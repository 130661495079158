// @import "../../Theme/color";

.containerInput {
  width: 302px;
  height: 56px;
  background-color: #f4eff4;
  textarea {
    // border-bottom-color: $Primary6d;
  }
  
  .css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    // color: $Primary6d;
  }

  // .css-cio0x1-MuiInputBase-root-MuiFilledInput-root:after {
  //   border-bottom-color: $Primary6d;
  // }
  // .css-f6v4u4-MuiInputBase-root-MuiFilledInput-root:after {
  //   border-bottom-color: $Primary6d;
  // }
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: #F4EFF4 !important;
}
.containerFilledInput {
  input {
    padding: 0 0 0 30px;
    font-family: Montserrat;
  }
  input::placeholder {
    margin: auto;
    font-family: Montserrat;
    font-size: 16px;
  }
}
